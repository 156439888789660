$v_color_text: #231815;

$v_color_main: #a18c35;
$v_color_ui: #24416a;
$v_color_disabled: #9fa0a0;
$v_color_bg_light: #f7f8f8;
$v_color_border: #ccc;
$v_color_accent: #df6160;

$v_color_border_input: #231815;

$v_radius: 4px;

$ORANGE_100: #ff99001a;
$ORANGE_500: #ff9900;
$PRIMARY: $ORANGE_500;

$NAVY_100: #24416a1a;
$NAVY_500: #24416a;
$SECONDARY: $NAVY_500;

// NOTE: このファイルはgulpで自動生成しているので、手動での編集はしないでください
@use "iconhash" as *;
@font-face {
	font-family: "icon";
	src: url('../../fonts/icon.woff#{$iconhash}') format('woff'),
		url('../../fonts/icon.ttf#{$iconhash}') format('truetype');
}

.icon,
[class*=" icon-"],
[class^="icon-"] {
  display: inline-block;
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-spin {
  animation: icon-spin 2s infinite linear
}

.icon-pulse {
  animation: icon-spin 1s infinite steps(8)
}

@keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}


.icon-acupuncture-diagnosis:before{ content:"\F001"}
$icon_acupuncture-diagnosis:"\F001";

.icon-add:before{ content:"\F002"}
$icon_add:"\F002";

.icon-aftercare:before{ content:"\F003"}
$icon_aftercare:"\F003";

.icon-bed:before{ content:"\F004"}
$icon_bed:"\F004";

.icon-calendar:before{ content:"\F005"}
$icon_calendar:"\F005";

.icon-card:before{ content:"\F006"}
$icon_card:"\F006";

.icon-case:before{ content:"\F007"}
$icon_case:"\F007";

.icon-check-circle:before{ content:"\F008"}
$icon_check-circle:"\F008";

.icon-check:before{ content:"\F009"}
$icon_check:"\F009";

.icon-coupon:before{ content:"\F00A"}
$icon_coupon:"\F00A";

.icon-cross:before{ content:"\F00B"}
$icon_cross:"\F00B";

.icon-fax:before{ content:"\F00C"}
$icon_fax:"\F00C";

.icon-genre:before{ content:"\F00D"}
$icon_genre:"\F00D";

.icon-good:before{ content:"\F00E"}
$icon_good:"\F00E";

.icon-history:before{ content:"\F00F"}
$icon_history:"\F00F";

.icon-home:before{ content:"\F010"}
$icon_home:"\F010";

.icon-hospital-diagnosis:before{ content:"\F011"}
$icon_hospital-diagnosis:"\F011";

.icon-medical-history:before{ content:"\F012"}
$icon_medical-history:"\F012";

.icon-menu:before{ content:"\F013"}
$icon_menu:"\F013";

.icon-more:before{ content:"\F014"}
$icon_more:"\F014";

.icon-next:before{ content:"\F015"}
$icon_next:"\F015";

.icon-place:before{ content:"\F016"}
$icon_place:"\F016";

.icon-post:before{ content:"\F017"}
$icon_post:"\F017";

.icon-reserve:before{ content:"\F018"}
$icon_reserve:"\F018";

.icon-review:before{ content:"\F019"}
$icon_review:"\F019";

.icon-search:before{ content:"\F01A"}
$icon_search:"\F01A";

.icon-selection:before{ content:"\F01B"}
$icon_selection:"\F01B";

.icon-staff:before{ content:"\F01C"}
$icon_staff:"\F01C";

.icon-star:before{ content:"\F01D"}
$icon_star:"\F01D";

.icon-station:before{ content:"\F01E"}
$icon_station:"\F01E";

.icon-tel:before{ content:"\F01F"}
$icon_tel:"\F01F";

.icon-time:before{ content:"\F020"}
$icon_time:"\F020";

.icon-treatments-times:before{ content:"\F021"}
$icon_treatments-times:"\F021";

.icon-url:before{ content:"\F022"}
$icon_url:"\F022";

.icon-user:before{ content:"\F023"}
$icon_user:"\F023";

.icon-yen:before{ content:"\F024"}
$icon_yen:"\F024";


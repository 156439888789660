@use 'v' as *;

.headerSearch {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 12px;

  &__row {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    text-align: center;
    font-size: 16px;
    flex-shrink: 0;
  }

  &__input {
    width: 100%;
    font-size: 16px;
    text-align: center;
    line-height: 1;
    padding: 6px 10px;
    margin: 0;
    border: 1px solid #231815;
    border-radius: 4px;
    box-sizing: border-box;
  }

  &__submit {
    width: 40px;
    box-sizing: border-box;
    background: #24416a;
    border: 1px solid #24416a;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    line-height: 1;
    padding: 6px 10px;
    margin-left: 10px;
    text-decoration: none;
    text-align: center;
  }
}

#headerRoot {
  height: 56px;
}

@use 'v' as *;

.salon-seo-text {
  display: none;
}

// 汎用 ==========
.searchContainer {
  padding: 0 12px;
}
.salon-box {
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
}

.salon-box-h {
  color: $v_color_main;
  text-align: center;
  font-size: 18px;
  margin-top: 5em;
  font-weight: normal;
}

.salon-box-action {
  text-align: center;
  margin: 30px 0 0;
  font-size: 16px;
}

.salon-box-action-link {
  text-decoration: underline;
  cursor: pointer;

  &,
  &:link,
  &:visited {
    color: $v_color_ui;
  }
}

// 予約パーツ =========
.salon-contact {
  &.fixed {
    position: fixed;
    width: 100%;
    left: 0;
    padding: 14px 8px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    background-color: rgb(255, 255, 255);
    margin: 0;
    bottom: 0;
    z-index: 30;
    display: flex;
    flex-wrap: nowrap;
    line-height: 1;
  }
}

.salon-contact-tel {
  width: 65px;
  margin-right: 8px;
}

.salon-contact-tel-link {
  display: block;
  background: #efefef;
  text-align: center;
  padding: 1px 0 0;
  text-decoration: none;
  height: 45px;
  border-radius: 4px;

  &,
  &:link,
  &:visited {
    color: $v_color_text;
  }
}

.salon-contact-tel-icon {
  font-size: 30px;
}

.salon-contact-tel-label {
  display: block;
  font-size: 10px;
}

.salon-contact-web {
  flex-grow: 1;
}

.salon-contact-web-link {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $PRIMARY;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  padding: 6px;
  height: 45px;
  border-radius: 4px;

  &,
  &:link,
  &:visited {
    color: #fff;
  }

  i {
    margin-right: 0.25em;
  }
}

.salon-contact-web-icon {
  font-size: 20px;
  margin-right: 0.2em;
}

.salonDetail__contact {
  gap: 6px;
  background-color: #fff;
  padding: 0.9em;
  border-top: 1px solid #ccc;

  .salon-contact-tel {
    width: 60px;
  }

  .salon-contact-web-link,
  .salon-contact-tel-link {
    border-radius: 5px;
  }

  .salon-contact-tel-icon {
    font-size: 20px;
    margin-bottom: 0.1em;
  }

  .salon-contact-tel-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .salon-contact-web-link {
    font-size: 14px;
  }
}

// 検索結果表示 ===================
.searchHeader {
  margin-bottom: 20px;
  &__title {
    font-size: 14px;
    font-weight: bold;
    margin-top: 0.75em;
    margin-bottom: 0;
  }
  &__result {
    font-size: 12px;
    &Total {
      font-size: 14px;
      color: #df6160;
      font-weight: bold;
    }
  }
}

.salon-list-info {
  font-size: 14px;
  margin: 1em;
}
.salon-list-total {
  color: #df6160;
  font-weight: bold;
}

.salon-list-item {
  padding-bottom: 20px;
  border-bottom: 8px solid $v_color_bg_light;
}

.salon-list-item-link {
  text-decoration: none;
  display: block;

  &,
  &:link,
  &:visited {
    color: $v_color_text;
  }
}

.salon-list-item-img {
  display: block;
  text-align: center;
  margin: 0;
  padding: 28px 24px 0;
  position: relative;

  span {
    position: absolute;
    bottom: 4px;
    left: 50%;
    width: 90px;
    margin-left: 18px;

    img {
      width: 100%;
    }
  }
}

.salon-list-item-img-img {
  max-width: 100%;
}

//詳細画面下部のリンクに関するcss
.salon-list-item-img-img-detail {
  max-width: 50%;
}

.salon-outline-h-detail {
  font-family: serif;
  font-weight: bold;
  font-size: 28px;
  line-height: 1.4;
  margin-top: 0;

  .salon-list-item & {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.salon-outline-prtittle-detail {
  font-size: 14px;
  font-weight: normal;
  color: #a18c35;
}

.salon-inside-link {
  margin-bottom: 10px;

  a {
    display: block;
    padding: 15px 0px 15px 10px;
    font-size: 14px;
    vertical-align: baseline;
    background: transparent;
    color: #254a72;
    text-decoration: none;
  }
}

.salon-similar-link {
  margin-bottom: 20px;
  min-height: 190px;
  overflow: hidden;

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 35px;
    border-radius: 0.5em;
    float: left;
    border: 1px solid #bfcace;
    box-sizing: border-box;
    padding: 8px;
    margin: 6px 4px;
    font-size: 12px;
    color: #24416a;
  }

  a:hover {
    text-decoration: none;
  }
}

.notesOnReservation {
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 30px;

  &__wrap {
    border: 2px solid #cccccc;
    padding: 18px;
  }

  &__title {
    font-weight: bold;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0.75em;
  }

  &__content {
  }

  &__contentText {
    margin: 0.5em 0;

    strong {
      color: #ff0000;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 15px;

    &Button {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background: #1a3157;
      border-radius: 4px;
      padding: 0.5em 1em;
    }
  }
}

.complete {
  line-height: 1.5;
  &Header {
    margin-bottom: 20px;
    &__title {
      font-size: 18px;
      margin-bottom: 0.75em;
    }
  }
  &Body {
    p + p {
      margin-top: 1em;
    }
  }
  &__pageLinks {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
  &__pageLink {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    max-width: 320px;
    border-radius: 4px;
    padding: 0.7em 1em;
    color: #fff;
    background: #1a3157;
  }
}

.reservationDetails {
  margin-bottom: 24px;
  &__title {
    font-size: 18px;
    margin-bottom: 0.75em;
  }
  &__contents {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  &__content {
    display: flex;
    font-weight: bold;
    flex-direction: column;
    dt {
      &:before {
        content: '■';
      }
    }
  }
}
.contact-main {
  .formActions {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;

    .formAction {
      display: flex;
      justify-content: center;
      &__button {
        width: 85%;
        max-width: 320px;
        border-radius: 4px;
        padding: 0.7em 1em;

        &--submit {
          color: #fff;
          background: #1a3157;
        }

        &--previous {
          background: #efefef;
        }
      }
    }
  }

  select {
    background: transparent;
    font: inherit;
    appearance: none;
  }
  .select {
    width: 100%;

    &Wrapper {
      position: relative;

      &--sm {
        max-width: 30%;
      }

      &:after {
        font-size: 16px;
        font-family: 'FontAwesome';
        content: '\f078';
        position: absolute;
        right: 0.5em;
        top: calc(50% - 10px);
        pointer-events: none;
      }
    }
  }
  button {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    font: inherit;
    outline: none;
    border: none;
  }

  .reservation-input-fields {
    display: flex;
    flex-direction: column;
    gap: 30px;

    input[type='text'],
    input[type='email'],
    input[type='tel'],
    textarea,
    select {
      margin: 0;
      width: 100%;
      max-width: 100%;
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
      outline: 0;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 1.3em;
      font-size: 100%;
      padding: 0.7em 1em;
      background: #fff;
      border: 1.9px solid rgba(34, 36, 38, 0.15);
      color: rgba(0, 0, 0, 0.87);
      border-radius: 0.28571429rem;
      -webkit-transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
      transition: box-shadow 0.1s ease, border-color 0.1s ease,
        -webkit-box-shadow 0.1s ease;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    input[type='text'],
    input[type='email'],
    input[type='tel'],
    textarea,
    select {
      height: 50px;
    }

    .textarea {
      height: 150px;
    }

    input:focus,
    textarea:focus {
      border-color: #24416a;
      background: #fff;
      color: rgba(0, 0, 0, 0.8);
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .label {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }

    .required-mark,
    .not-required-mark {
      border-radius: 3px;
      font-size: 12px;
      margin-right: 10px;
      padding: 3px 0.8em;
      letter-spacing: 0.2em;
      color: white;
    }

    .required-mark {
      background: #ff1a00;
    }

    .not-required-mark {
      background: #999;
    }

    .inputRadioGroup {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .g-btn-field-back {
    margin: 30px 0 30px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .g-btn-grey {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    background: #efefef;
    border: 1px solid #efefef;
    color: black;
    font-size: 16px;
    border-radius: 4px;
    line-height: 1;
    padding: 6px 10px;
    text-decoration: none;
    text-align: center;
    width: 100%;
    height: 34px;
  }

  .g-btn-grey.mode-full {
    width: 266px;
    font-size: 14px;
    padding: 9px 10px;
  }

  .tel-button {
    margin-top: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    background: #efefef;
    border: 1px solid #efefef;
    font-size: 14px;
    border-radius: 4px;
    line-height: 1;
    padding: 6px 10px;
    text-decoration: none;
    text-align: center;
    width: 100px;
    height: 34px;
  }
}
.salonInformationLists {
  display: flex;
  -webkit-box-align: start;
  align-items: start;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
}
.salonInformationList {
  display: flex;
  -webkit-box-align: start;
  align-items: start;
  flex-direction: row;
  gap: 0.5rem;
  font-size: 14px;
  width: 100%;
  &__title {
    flex-shrink: 0;
    font-weight: bold;
    max-width: 7rem;
    width: 26.5%;
  }
  &__content {
    display: flex;
    -webkit-box-align: start;
    align-items: start;
    flex-direction: row;
    gap: 0.5rem;
  }
}

.salonTroubleList {
  list-style-type: none;
  column-gap: 5px;
  display: flex;
  flex-wrap: wrap;
  &__item {
    &:after {
      content: '/';
      margin-left: 5px;
    }
  }
}
.stationList {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

// google adsense ===================
.sp-ad {
  width: 320px;
  height: 100px;
  text-align: center;
  margin: 30px auto;
  overflow: hidden;
}

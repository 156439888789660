@use 'v' as *;

.footer {
  background: $v_color_bg_light;
}
.footer-h {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}
.footer-h-link {
  display: block;
  padding: 15px 0;
}
.footer-h-img {
  max-width: 140px;
  height: auto;
}
.footer-nav-items {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.footer-nav-link {
  display: block;
  padding: 8px 0;
  line-height: 1.5;
  font-size: 13px;
  &:link,
  &:visited {
    color: $v_color_text;
    text-decoration: none;
  }
}
.footer-copyright {
  font-size: 10px;
  text-align: center;
  margin-top: 3em;
}

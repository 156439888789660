.contentMain {
  margin-bottom: 80px;
}

.contentArticle {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.contentSection {
  line-height: 1.4;

  .contentSection__text {
    font-size: 13px;
    margin-bottom: 1em;
  }

  &__wrapper {
    padding: 0 20px;
  }

  &__title {
    font-size: 16px;
    display: block;
    padding: 10px;
    background: #a18c35;
    margin-bottom: 1em;
    color: #fff;
  }

  &__pageLink {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.container {
  padding: 0 12px;
  margin: 0 auto;
}

@use 'v' as *;

.breadcrumb {
  margin: 20px 0;
  white-space: nowrap;
  font-size: 12px;
  width: 100%;
  overflow-x: auto;
}

.breadcrumb-items {
  margin: 0;
  padding: 0;
}
.breadcrumb-item {
  display: inline-block;
  margin: 0;
  padding: 0;
  &:before {
    content: '>';
  }
  &:first-child:before {
    content: '';
  }
}
.breadcrumb-link {
  display: inline-block;
  &:link,
  &:visited {
    color: $v_color_text;
    text-decoration: none;
  }
}

@charset "UTF-8";
.container {
  padding: 0 24px;
  margin: 0 auto;
}

/* Document
 * ========================================================================== */
/**
 * Add border box sizing in all browsers (opinionated).
 */
*,
::before,
::after {
  box-sizing: border-box;
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Change the line height in all browsers (opinionated).
 * 3. Use a 4-space tab width in all browsers (opinionated).
 * 4. Remove the grey highlight on links in iOS (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */
html {
  cursor: default; /* 1 */
  line-height: 1.5; /* 2 */
  -moz-tab-size: 4; /* 3 */
  tab-size: 4; /* 3 */
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%; /* 5 */
  -webkit-text-size-adjust: 100%; /* 5 */
  word-break: break-word; /* 6 */
}

/* Sections
 * ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

/**
 * 1. Add the correct sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */
main {
  display: block;
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none;
  padding: 0;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
 * ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */
/*
 * Change the alignment on media elements in all browsers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on iframes in all browsers (opinionated).
 */
iframe {
  border-style: none;
}

/**
 * Remove the border on images within links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg:not([fill]) {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Tabular data
 * ========================================================================== */
/**
 * Collapse border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse;
}

/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Correct the padding in Firefox.
 */
fieldset {
  border: 1px solid #a0a0a0; /* 1 */
  padding: 0.35em 0.75em 0.625em; /* 2 */
}

/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction in all browsers (opinionated).
 */
textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
  resize: vertical; /* 3 */
}

/**
 * Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  padding: 0;
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* User interaction
 * ========================================================================== */
/*
 * 1. Remove the tapping delay in IE 10.
 * 2. Remove the tapping delay on clickable elements
      in all browsers (opinionated).
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation; /* 1 */
  touch-action: manipulation; /* 2 */
}

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

/* Accessibility
 * ========================================================================== */
/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */
[aria-busy=true] {
  cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */
[aria-controls] {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */
[aria-disabled=true],
[disabled] {
  cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */
[aria-hidden=false][hidden] {
  display: initial;
}

[aria-hidden=false][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

body {
  font-family: sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../../fonts/slick.eot");
  src: url("../../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../../fonts/slick.woff") format("woff"), url("../../fonts/slick.ttf") format("truetype"), url("../../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

#fancybox-content {
  -webkit-overflow-scrolling: touch;
  overflow: auto !important;
}

#fancybox-close {
  right: -25px !important;
}

@font-face {
  font-family: "icon";
  src: url("../../fonts/icon.woff?v=63bd367b95aedb40bc328e69c4aba6e3") format("woff"), url("../../fonts/icon.ttf?v=63bd367b95aedb40bc328e69c4aba6e3") format("truetype");
}
.icon,
[class*=" icon-"],
[class^=icon-] {
  display: inline-block;
  font-family: "icon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-spin {
  animation: icon-spin 2s infinite linear;
}

.icon-pulse {
  animation: icon-spin 1s infinite steps(8);
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.icon-acupuncture-diagnosis:before {
  content: "\f001";
}

.icon-add:before {
  content: "\f002";
}

.icon-aftercare:before {
  content: "\f003";
}

.icon-bed:before {
  content: "\f004";
}

.icon-calendar:before {
  content: "\f005";
}

.icon-card:before {
  content: "\f006";
}

.icon-case:before {
  content: "\f007";
}

.icon-check-circle:before {
  content: "\f008";
}

.icon-check:before {
  content: "\f009";
}

.icon-coupon:before {
  content: "\f00a";
}

.icon-cross:before {
  content: "\f00b";
}

.icon-fax:before {
  content: "\f00c";
}

.icon-genre:before {
  content: "\f00d";
}

.icon-good:before {
  content: "\f00e";
}

.icon-history:before {
  content: "\f00f";
}

.icon-home:before {
  content: "\f010";
}

.icon-hospital-diagnosis:before {
  content: "\f011";
}

.icon-medical-history:before {
  content: "\f012";
}

.icon-menu:before {
  content: "\f013";
}

.icon-more:before {
  content: "\f014";
}

.icon-next:before {
  content: "\f015";
}

.icon-place:before {
  content: "\f016";
}

.icon-post:before {
  content: "\f017";
}

.icon-reserve:before {
  content: "\f018";
}

.icon-review:before {
  content: "\f019";
}

.icon-search:before {
  content: "\f01a";
}

.icon-selection:before {
  content: "\f01b";
}

.icon-staff:before {
  content: "\f01c";
}

.icon-star:before {
  content: "\f01d";
}

.icon-station:before {
  content: "\f01e";
}

.icon-tel:before {
  content: "\f01f";
}

.icon-time:before {
  content: "\f020";
}

.icon-treatments-times:before {
  content: "\f021";
}

.icon-url:before {
  content: "\f022";
}

.icon-user:before {
  content: "\f023";
}

.icon-yen:before {
  content: "\f024";
}

.info-main-h {
  padding: 0 6px 0 12px;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.4;
  margin-top: 0;
}

.info-outline {
  padding: 0 24px;
  font-size: 16px;
}
.info-outline .info-sub-h {
  margin-top: 30px;
  font-weight: bold;
  font-size: 18px;
}
.info-outline .info-contact {
  margin-bottom: 30px;
}

.g-btn {
  box-sizing: border-box;
  display: inline-block;
  border: none;
  background: #24416a;
  border: 1px solid #24416a;
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
  line-height: 1;
  padding: 6px 10px;
  text-decoration: none;
  text-align: center;
}
.g-btn.mode-full {
  width: 100%;
  font-size: 14px;
  padding: 9px 10px;
}
.g-btn.mode-s {
  font-size: 16px;
  padding: 8px;
}

.g-form-field {
  margin: 10px 0 16px;
}

.g-form-label {
  padding-bottom: 8px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5);
  display: block;
}

.g-input-txt {
  border-radius: 4px;
  border: 1px solid #231815;
  width: 100%;
  font-size: 16px;
  padding: 6px 10px;
  line-height: 1;
}
.g-input-txt::placeholder {
  color: #9fa0a0;
}

.g-input-select {
  border-radius: 4px;
  border: 1px solid #231815;
  width: 100%;
  font-size: 16px;
  padding: 6px 10px;
  line-height: 1;
  background: #fff;
  box-shadow: inset 0 2px 6px #eee;
  margin-bottom: 16px;
}
.g-input-select::placeholder {
  color: #9fa0a0;
}

.g-section-title {
  font-size: 16px;
  padding: 15px 10px;
  background: #a18c35;
  color: #fff;
}
.g-section-title.t-shadow {
  text-shadow: 0 -1px 0 #7b6b28;
}

.g-section-content {
  margin: 20px 10px 30px;
  font-size: 14px;
  color: #59483e;
}

.g-alert {
  border: 1px solid #999999;
  background-color: #eeeded;
  border-radius: 4px;
  padding: 5px 5px;
}
.g-alert p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.g-alert.is-error {
  border-color: #d75555;
  background-color: #ffc9c9;
  color: #d75555;
}

.u-text-left {
  text-align: left;
}

.u-text-center {
  text-align: center;
}

.u-text-right {
  text-align: right;
}

.u-text-bold {
  font-weight: bold;
}

.headerSearch {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 12px;
}
.headerSearch__row {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
}
.headerSearch__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  text-align: center;
  font-size: 16px;
  flex-shrink: 0;
}
.headerSearch__input {
  width: 100%;
  font-size: 16px;
  text-align: center;
  line-height: 1;
  padding: 6px 10px;
  margin: 0;
  border: 1px solid #231815;
  border-radius: 4px;
  box-sizing: border-box;
}
.headerSearch__submit {
  width: 40px;
  box-sizing: border-box;
  background: #24416a;
  border: 1px solid #24416a;
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
  line-height: 1;
  padding: 6px 10px;
  margin-left: 10px;
  text-decoration: none;
  text-align: center;
}

#headerRoot {
  height: 56px;
}

.breadcrumb {
  margin: 20px 0;
  white-space: nowrap;
  font-size: 12px;
  width: 100%;
  overflow-x: auto;
}

.breadcrumb-items {
  margin: 0;
  padding: 0;
}

.breadcrumb-item {
  display: inline-block;
  margin: 0;
  padding: 0;
}
.breadcrumb-item:before {
  content: ">";
}
.breadcrumb-item:first-child:before {
  content: "";
}

.breadcrumb-link {
  display: inline-block;
}
.breadcrumb-link:link, .breadcrumb-link:visited {
  color: #231815;
  text-decoration: none;
}

.pagination {
  padding-top: 8px;
}
.pagination__group {
  display: flex;
  justify-content: space-between;
}
.pagination__count {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
}
.pagination__button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #24416a;
  width: 36px;
  height: 36px;
  padding: 0.5em;
  background: #fff;
  border: 1px solid #24416a;
  border-radius: 3px;
}
.pagination__button--disabled {
  color: #ccc;
  pointer-events: none;
  border-color: #ccc;
}

.footer {
  background: #f7f8f8;
}

.footer-h {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

.footer-h-link {
  display: block;
  padding: 15px 0;
}

.footer-h-img {
  max-width: 140px;
  height: auto;
}

.footer-nav-items {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer-nav-link {
  display: block;
  padding: 8px 0;
  line-height: 1.5;
  font-size: 13px;
}
.footer-nav-link:link, .footer-nav-link:visited {
  color: #231815;
  text-decoration: none;
}

.footer-copyright {
  font-size: 10px;
  text-align: center;
  margin-top: 3em;
}

.salon-seo-text {
  display: none;
}

.searchContainer {
  padding: 0 12px;
}

.salon-box {
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
}

.salon-box-h {
  color: #a18c35;
  text-align: center;
  font-size: 18px;
  margin-top: 5em;
  font-weight: normal;
}

.salon-box-action {
  text-align: center;
  margin: 30px 0 0;
  font-size: 16px;
}

.salon-box-action-link {
  text-decoration: underline;
  cursor: pointer;
}
.salon-box-action-link, .salon-box-action-link:link, .salon-box-action-link:visited {
  color: #24416a;
}

.salon-contact.fixed {
  position: fixed;
  width: 100%;
  left: 0;
  padding: 14px 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  background-color: rgb(255, 255, 255);
  margin: 0;
  bottom: 0;
  z-index: 30;
  display: flex;
  flex-wrap: nowrap;
  line-height: 1;
}

.salon-contact-tel {
  width: 65px;
  margin-right: 8px;
}

.salon-contact-tel-link {
  display: block;
  background: #efefef;
  text-align: center;
  padding: 1px 0 0;
  text-decoration: none;
  height: 45px;
  border-radius: 4px;
}
.salon-contact-tel-link, .salon-contact-tel-link:link, .salon-contact-tel-link:visited {
  color: #231815;
}

.salon-contact-tel-icon {
  font-size: 30px;
}

.salon-contact-tel-label {
  display: block;
  font-size: 10px;
}

.salon-contact-web {
  flex-grow: 1;
}

.salon-contact-web-link {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff9900;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  padding: 6px;
  height: 45px;
  border-radius: 4px;
}
.salon-contact-web-link, .salon-contact-web-link:link, .salon-contact-web-link:visited {
  color: #fff;
}
.salon-contact-web-link i {
  margin-right: 0.25em;
}

.salon-contact-web-icon {
  font-size: 20px;
  margin-right: 0.2em;
}

.salonDetail__contact {
  gap: 6px;
  background-color: #fff;
  padding: 0.9em;
  border-top: 1px solid #ccc;
}
.salonDetail__contact .salon-contact-tel {
  width: 60px;
}
.salonDetail__contact .salon-contact-web-link,
.salonDetail__contact .salon-contact-tel-link {
  border-radius: 5px;
}
.salonDetail__contact .salon-contact-tel-icon {
  font-size: 20px;
  margin-bottom: 0.1em;
}
.salonDetail__contact .salon-contact-tel-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.salonDetail__contact .salon-contact-web-link {
  font-size: 14px;
}

.searchHeader {
  margin-bottom: 20px;
}
.searchHeader__title {
  font-size: 14px;
  font-weight: bold;
  margin-top: 0.75em;
  margin-bottom: 0;
}
.searchHeader__result {
  font-size: 12px;
}
.searchHeader__resultTotal {
  font-size: 14px;
  color: #df6160;
  font-weight: bold;
}

.salon-list-info {
  font-size: 14px;
  margin: 1em;
}

.salon-list-total {
  color: #df6160;
  font-weight: bold;
}

.salon-list-item {
  padding-bottom: 20px;
  border-bottom: 8px solid #f7f8f8;
}

.salon-list-item-link {
  text-decoration: none;
  display: block;
}
.salon-list-item-link, .salon-list-item-link:link, .salon-list-item-link:visited {
  color: #231815;
}

.salon-list-item-img {
  display: block;
  text-align: center;
  margin: 0;
  padding: 28px 24px 0;
  position: relative;
}
.salon-list-item-img span {
  position: absolute;
  bottom: 4px;
  left: 50%;
  width: 90px;
  margin-left: 18px;
}
.salon-list-item-img span img {
  width: 100%;
}

.salon-list-item-img-img {
  max-width: 100%;
}

.salon-list-item-img-img-detail {
  max-width: 50%;
}

.salon-outline-h-detail {
  font-family: serif;
  font-weight: bold;
  font-size: 28px;
  line-height: 1.4;
  margin-top: 0;
}
.salon-list-item .salon-outline-h-detail {
  font-size: 18px;
  margin-bottom: 10px;
}

.salon-outline-prtittle-detail {
  font-size: 14px;
  font-weight: normal;
  color: #a18c35;
}

.salon-inside-link {
  margin-bottom: 10px;
}
.salon-inside-link a {
  display: block;
  padding: 15px 0px 15px 10px;
  font-size: 14px;
  vertical-align: baseline;
  background: transparent;
  color: #254a72;
  text-decoration: none;
}

.salon-similar-link {
  margin-bottom: 20px;
  min-height: 190px;
  overflow: hidden;
}
.salon-similar-link a {
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 35px;
  border-radius: 0.5em;
  float: left;
  border: 1px solid #bfcace;
  box-sizing: border-box;
  padding: 8px;
  margin: 6px 4px;
  font-size: 12px;
  color: #24416a;
}
.salon-similar-link a:hover {
  text-decoration: none;
}

.notesOnReservation {
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.notesOnReservation__wrap {
  border: 2px solid #cccccc;
  padding: 18px;
}
.notesOnReservation__title {
  font-weight: bold;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0.75em;
}
.notesOnReservation__contentText {
  margin: 0.5em 0;
}
.notesOnReservation__contentText strong {
  color: #ff0000;
}
.notesOnReservation__actions {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.notesOnReservation__actionsButton {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #1a3157;
  border-radius: 4px;
  padding: 0.5em 1em;
}

.complete {
  line-height: 1.5;
}
.completeHeader {
  margin-bottom: 20px;
}
.completeHeader__title {
  font-size: 18px;
  margin-bottom: 0.75em;
}
.completeBody p + p {
  margin-top: 1em;
}
.complete__pageLinks {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.complete__pageLink {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  max-width: 320px;
  border-radius: 4px;
  padding: 0.7em 1em;
  color: #fff;
  background: #1a3157;
}

.reservationDetails {
  margin-bottom: 24px;
}
.reservationDetails__title {
  font-size: 18px;
  margin-bottom: 0.75em;
}
.reservationDetails__contents {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.reservationDetails__content {
  display: flex;
  font-weight: bold;
  flex-direction: column;
}
.reservationDetails__content dt:before {
  content: "■";
}

.contact-main .formActions {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
}
.contact-main .formActions .formAction {
  display: flex;
  justify-content: center;
}
.contact-main .formActions .formAction__button {
  width: 85%;
  max-width: 320px;
  border-radius: 4px;
  padding: 0.7em 1em;
}
.contact-main .formActions .formAction__button--submit {
  color: #fff;
  background: #1a3157;
}
.contact-main .formActions .formAction__button--previous {
  background: #efefef;
}
.contact-main select {
  background: transparent;
  font: inherit;
  appearance: none;
}
.contact-main .select {
  width: 100%;
}
.contact-main .selectWrapper {
  position: relative;
}
.contact-main .selectWrapper--sm {
  max-width: 30%;
}
.contact-main .selectWrapper:after {
  font-size: 16px;
  font-family: "FontAwesome";
  content: "\f078";
  position: absolute;
  right: 0.5em;
  top: calc(50% - 10px);
  pointer-events: none;
}
.contact-main button {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  font: inherit;
  outline: none;
  border: none;
}
.contact-main .reservation-input-fields {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.contact-main .reservation-input-fields input[type=text],
.contact-main .reservation-input-fields input[type=email],
.contact-main .reservation-input-fields input[type=tel],
.contact-main .reservation-input-fields textarea,
.contact-main .reservation-input-fields select {
  margin: 0;
  width: 100%;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.3em;
  font-size: 100%;
  padding: 0.7em 1em;
  background: #fff;
  border: 1.9px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.contact-main .reservation-input-fields input[type=text],
.contact-main .reservation-input-fields input[type=email],
.contact-main .reservation-input-fields input[type=tel],
.contact-main .reservation-input-fields textarea,
.contact-main .reservation-input-fields select {
  height: 50px;
}
.contact-main .reservation-input-fields .textarea {
  height: 150px;
}
.contact-main .reservation-input-fields input:focus,
.contact-main .reservation-input-fields textarea:focus {
  border-color: #24416a;
  background: #fff;
  color: rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.contact-main .reservation-input-fields .label {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.contact-main .reservation-input-fields .required-mark,
.contact-main .reservation-input-fields .not-required-mark {
  border-radius: 3px;
  font-size: 12px;
  margin-right: 10px;
  padding: 3px 0.8em;
  letter-spacing: 0.2em;
  color: white;
}
.contact-main .reservation-input-fields .required-mark {
  background: #ff1a00;
}
.contact-main .reservation-input-fields .not-required-mark {
  background: #999;
}
.contact-main .reservation-input-fields .inputRadioGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contact-main .g-btn-field-back {
  margin: 30px 0 30px;
  padding-left: 24px;
  padding-right: 24px;
}
.contact-main .g-btn-grey {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  background: #efefef;
  border: 1px solid #efefef;
  color: black;
  font-size: 16px;
  border-radius: 4px;
  line-height: 1;
  padding: 6px 10px;
  text-decoration: none;
  text-align: center;
  width: 100%;
  height: 34px;
}
.contact-main .g-btn-grey.mode-full {
  width: 266px;
  font-size: 14px;
  padding: 9px 10px;
}
.contact-main .tel-button {
  margin-top: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  background: #efefef;
  border: 1px solid #efefef;
  font-size: 14px;
  border-radius: 4px;
  line-height: 1;
  padding: 6px 10px;
  text-decoration: none;
  text-align: center;
  width: 100px;
  height: 34px;
}

.salonInformationLists {
  display: flex;
  -webkit-box-align: start;
  align-items: start;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
}

.salonInformationList {
  display: flex;
  -webkit-box-align: start;
  align-items: start;
  flex-direction: row;
  gap: 0.5rem;
  font-size: 14px;
  width: 100%;
}
.salonInformationList__title {
  flex-shrink: 0;
  font-weight: bold;
  max-width: 7rem;
  width: 26.5%;
}
.salonInformationList__content {
  display: flex;
  -webkit-box-align: start;
  align-items: start;
  flex-direction: row;
  gap: 0.5rem;
}

.salonTroubleList {
  list-style-type: none;
  column-gap: 5px;
  display: flex;
  flex-wrap: wrap;
}
.salonTroubleList__item:after {
  content: "/";
  margin-left: 5px;
}

.stationList {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.sp-ad {
  width: 320px;
  height: 100px;
  text-align: center;
  margin: 30px auto;
  overflow: hidden;
}

.userReview-meta {
  font-size: 0.75em;
  margin-bottom: 1em;
}
.userReview-rate {
  display: flex;
  align-items: center;
}
.userReview-rate-num {
  color: #df6160;
  font-size: 16px;
  font-weight: normal;
  margin-left: 0.2em;
}
.userReview-verified {
  color: #a18c35;
  display: inline-block;
  white-space: nowrap;
  margin-right: 0.4em;
}
.userReview-action {
  text-align: center;
  margin: 18px 0;
}
.userReview-action .g-btn {
  font-size: 16px;
  min-width: 250px;
}
.userReview-link {
  display: block;
  text-decoration: none;
  color: #231815;
  line-height: 1.5;
  padding-bottom: 1em;
}

.salon-review-h {
  text-align: center;
}

.salon-review-detail-h-action {
  margin: 30px 0 46px;
}

.search-input {
  padding-bottom: 8em;
  background: #fff;
}

.search-input-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  background: #998425;
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin: 0;
  padding: 0.6em;
}

.search-input-item {
  position: relative;
}
.search-input-item.mode-off {
  display: none;
}

.search-input-item-h {
  font-size: 14px;
  color: #59483e;
  margin: 0 0 0.8em;
  padding: 0.8em 0.8em 0;
}

.search-input-item-subh {
  background: #998425;
  color: #fff;
  font-size: 14px;
  font-size: 16px;
  margin: 0;
  padding: 0.6em;
}

.search-input-input-wrap {
  margin: 10px 10px 0;
  white-space: nowrap;
}

.search-input-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
  margin: 0;
  padding: 1em;
}

.search-input-arrow {
  color: #e2ded2;
  display: inline-block;
  text-align: center;
  width: 10%;
}
.search-input-arrow:before {
  content: ">";
}

.search-input-select.mode-child {
  width: 90%;
}

.search-input-nav {
  position: absolute;
  right: 10px;
  top: 8px;
  margin: 0;
  padding: 0;
}

.search-input-nav-link {
  font-size: 12px;
  text-decoration: underline;
  color: #24416a;
}

.search-input-checks {
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
  overflow: hidden;
}
.search-input-checks:after {
  display: table;
  content: "";
  clear: both;
}

.search-input-check {
  width: 50%;
  float: left;
}
.search-input-check:nth-child(2n+1) {
  clear: left;
}

.search-input-check-label {
  display: block;
  position: relative;
  padding: 10px 8px 10px 30px;
  border: 1px dotted #e2ded2;
  border-bottom: 0;
  margin-left: -1px;
  color: #844b04;
  font-size: 14px;
  line-height: 1.55;
}

.search-input-check-chkbox {
  position: absolute;
  left: 10px;
  top: 14px;
}

.search-input-freeword {
  width: 61.8%;
}

.search-input-submit {
  margin: 0;
  padding-top: 0.8em !important;
  padding-bottom: 0.8em !important;
  font-size: 16px !important;
}

.contentMain {
  margin-bottom: 80px;
}

.contentArticle {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.contentSection {
  line-height: 1.4;
}
.contentSection .contentSection__text {
  font-size: 13px;
  margin-bottom: 1em;
}
.contentSection__wrapper {
  padding: 0 20px;
}
.contentSection__title {
  font-size: 16px;
  display: block;
  padding: 10px;
  background: #a18c35;
  margin-bottom: 1em;
  color: #fff;
}
.contentSection__pageLink {
  text-decoration: underline;
}
.contentSection__pageLink:hover {
  text-decoration: none;
}

.container {
  padding: 0 12px;
  margin: 0 auto;
}
@use 'v' as *;

.g-btn {
  box-sizing: border-box;
  display: inline-block;
  border: none;
  background: $v_color_ui;
  border: 1px solid $v_color_ui;
  color: #fff;
  font-size: 16px;
  border-radius: $v_radius;
  line-height: 1;
  padding: 6px 10px;
  text-decoration: none;
  text-align: center;

  &.mode-full {
    width: 100%;
    font-size: 14px;
    padding: 9px 10px;
  }

  &.mode-s {
    font-size: 16px;
    padding: 8px;
  }
}

.g-form-field {
  margin: 10px 0 16px;
}

.g-form-label {
  padding-bottom: 8px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5);
  display: block;
}

.g-input-txt {
  border-radius: $v_radius;
  border: 1px solid $v_color_border_input;
  width: 100%;
  font-size: 16px;
  padding: 6px 10px;
  line-height: 1;

  &::placeholder {
    color: $v_color_disabled;
  }
}

.g-input-select {
  border-radius: $v_radius;
  border: 1px solid $v_color_border_input;
  width: 100%;
  font-size: 16px;
  padding: 6px 10px;
  line-height: 1;
  background: #fff;
  box-shadow: inset 0 2px 6px #eee;
  margin-bottom: 16px;

  &::placeholder {
    color: $v_color_disabled;
  }
}

.g-section-title {
  font-size: 16px;
  padding: 15px 10px;
  background: #a18c35;
  color: #fff;

  &.t-shadow {
    text-shadow: 0 -1px 0 #7b6b28;
  }
}

.g-section-content {
  margin: 20px 10px 30px;
  font-size: 14px;
  color: #59483e;
}

.g-alert {
  border: 1px solid #999999;
  background-color: #eeeded;
  border-radius: 4px;
  padding: 5px 5px;

  p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  &.is-error {
    border-color: #d75555;
    background-color: #ffc9c9;
    color: #d75555;
  }
}

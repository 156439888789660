@use './v' as variable;
// レビュー ===================

.userReview {
  &-meta {
    font-size: 0.75em;
    margin-bottom: 1em;
  }

  &-rate {
    display: flex;
    align-items: center;

    &-num {
      color: variable.$v_color_accent;
      font-size: 16px;
      font-weight: normal;
      margin-left: 0.2em;
    }
  }

  &-verified {
    color: variable.$v_color_main;
    display: inline-block;
    white-space: nowrap;
    margin-right: 0.4em;
  }

  &-action {
    text-align: center;
    margin: 18px 0;

    .g-btn {
      font-size: 16px;
      min-width: 250px;
    }
  }

  &-link {
    display: block;
    text-decoration: none;
    color: variable.$v_color_text;
    line-height: 1.5;

    padding-bottom: 1em;
  }
}

.salon-review-h {
  text-align: center;
}

.salon-review-detail-h-action {
  margin: 30px 0 46px;
}

@use 'v' as *;

.pagination {
  padding-top: 8px;
  &__group {
    display: flex;
    justify-content: space-between;
  }
  &__count {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 12px;
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    color: #24416a;

    width: 36px;
    height: 36px;
    padding: 0.5em;

    background: #fff;
    border: 1px solid #24416a;
    border-radius: 3px;

    &--disabled {
      color: #ccc;
      pointer-events: none;
      border-color: #ccc;
    }
  }
}

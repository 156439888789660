.info-main-h {
  padding: 0 6px 0 12px;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.4;
  margin-top: 0;
}

.info-outline {
  padding: 0 24px;
  font-size: 16px;
  .info-sub-h {
    margin-top: 30px;
    font-weight: bold;
    font-size: 18px;
  }
  .info-contact {
    margin-bottom: 30px;
  }
}

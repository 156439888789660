@use 'v' as *;

.search-input {
  padding-bottom: 8em;
  background: #fff;
}
.search-input-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  background: #998425;
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin: 0;
  padding: 0.6em;
}
.search-input-item {
  position: relative;
  &.mode-off {
    display: none;
  }
}
.search-input-item-h {
  font-size: 14px;
  color: #59483e;
  margin: 0 0 0.8em;
  padding: 0.8em 0.8em 0;
}
.search-input-item-subh {
  background: #998425;
  color: #fff;
  font-size: 14px;
  font-size: 16px;
  margin: 0;
  padding: 0.6em;
}

.search-input-input-wrap {
  margin: 10px 10px 0;
  white-space: nowrap;
}

.search-input-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff9;
  margin: 0;
  padding: 1em;
}

.search-input-arrow {
  color: #e2ded2;
  display: inline-block;
  text-align: center;
  width: 10%;
  &:before {
    content: '>';
  }
}
.search-input-select.mode-child {
  width: 90%;
}
.search-input-nav {
  position: absolute;
  right: 10px;
  top: 8px;
  margin: 0;
  padding: 0;
}
.search-input-nav-link {
  font-size: 12px;
  text-decoration: underline;
  color: $v_color_ui;
}

.search-input-checks {
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
  overflow: hidden;
  &:after {
    display: table;
    content: '';
    clear: both;
  }
}
.search-input-check {
  width: 50%;
  float: left;
  &:nth-child(2n + 1) {
    clear: left;
  }
}
.search-input-check-label {
  display: block;
  position: relative;
  padding: 10px 8px 10px 30px;
  border: 1px dotted #e2ded2;
  border-bottom: 0;
  margin-left: -1px;
  color: #844b04;
  font-size: 14px;
  line-height: 1.55;
}
.search-input-check-chkbox {
  position: absolute;
  left: 10px;
  top: 14px;
}
.search-input-freeword {
  width: 61.8%;
}
.search-input-submit {
  margin: 0;
  padding-top: 0.8em !important;
  padding-bottom: 0.8em !important;
  font-size: 16px !important;
}
